import ai from "@/utils/ai";
import ls from '@/utils/ls'
import Vue from 'vue'
import i18n from '@/locales'
export default {
    state: {
        daily: null,
        dailyLoad: false,
        byDay: null,
        byDayLoad: false
    },
    getters: {
        dailyData: state => state['daily'],
        dailyLoading: state => state['dailyLoad'],
        byDayReport: state => state['byDay'],
        byDayReportLoading: state => state['byDayLoad']
    },
    mutations: {
        SET_DAILY (state, payload) {
            state.daily = payload
        },
        SET_DAILY_LOADER (state, payload) {
            state.dailyLoad = payload
        },
        SET_BY_DAY (state, payload) {
            state.byDay = payload
        },
        SET_BY_DAY_LOADER (state, payload) {
            state.byDayLoad = payload
        }
    },
    actions: {
        async getReportDaily ({ commit }, payload) {
            try {
                commit('SET_DAILY_LOADER', true)
                const data = await ai.get('/reports/daily', payload)
                commit('SET_DAILY', data)
                commit('SET_DAILY_LOADER', false)
                return data
            } catch (e) {
                commit('SET_DAILY_LOADER', false)
                return 'error'
            }
        },
        async getAllReportByDay ({ commit }, params) {
            try {
                commit('SET_BY_DAY_LOADER', true)
                const data = await ai.get('/reports/all-by-day', params)
                commit('SET_BY_DAY', data)
                commit('SET_BY_DAY_LOADER', false)
                return data
            } catch (e) {
                commit('SET_BY_DAY_LOADER', false)
                return 'error'
            }
        },
        async getEventReport ({ commit }, params) {
            try {
                const data = await ai.get('/reports/event', params)
                commit('SET_EVENT', data)
                return data
            } catch (e) {
                return 'error'
            }
        },
    }
}