import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from "@/locales";
import store from './store'
import ElementUI from 'element-ui';
import PortalVue from 'portal-vue'
import { timeFormat} from "@/utils";

import { Notification } from "element-ui";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Pagination from "@/components/Pagination.vue";
import EmptyBox from "@/components/EmptyBox.vue";
import Delete from "@/components/Delete.vue";
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

Vue.use(PortalVue)
Vue.config.productionTip = false
import './element-variables.scss'
import './global.css'
import './style.scss'
locale.use(lang)
dayjs.extend(utc);
dayjs.extend(timezone);

Vue.use(ElementUI);
window.notify = Notification;
Vue.prototype.$iUrl = process.env.VUE_APP_BASE_IMAGE_URL
Vue.prototype.$uploadUrl = process.env.VUE_APP_BASE_URL + '/upload'
Vue.prototype.$dParse = (date) => dayjs(date).format('DD.MM.YYYY')
Vue.prototype.$dParseM = (date) => dayjs(date).format('DD.MM')
Vue.prototype.$required = (val) => i18n.t(val) + i18n.t('required')
Vue.prototype.$timeParse = (date) => dayjs(date).format('HH:mm')
Vue.prototype.$bridge = new Vue()
Vue.prototype.$tf = timeFormat
Vue.component("Pagination", Pagination);
Vue.component("EmptyBox", EmptyBox);
Vue.component("Remover", Delete);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
