import Vue from 'vue'
import VueRouter from 'vue-router'
import guard from '@/utils/router.guard'
import routes from '@/constants/routes'


Vue.use(VueRouter)



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
router.beforeEach(guard)
export default router
