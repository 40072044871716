import { render, staticRenderFns } from "./auth-layout.vue?vue&type=template&id=2d8a3372&scoped=true"
import script from "./auth-layout.vue?vue&type=script&lang=js"
export * from "./auth-layout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d8a3372",
  null
  
)

export default component.exports