<script>
import dayjs from "dayjs";
export default {
  name: "ServerTime",
  data() {
    return {
      tt: new Date(),
      timer: null
    }
  },
  computed: {
    time() {
      return dayjs(this.tt).format('HH:mm:ss DD.MM.YYYY')
    }
  },
  mounted() {
    this.$store.dispatch('getServerTime').then(res => {
      if (res && res.time) {
        this.tt = res.time
        this.timer = setInterval(() => {
          this.tt = dayjs(this.tt).add(1, 'second')
        }, 1000)
      }
    })
  }
}
</script>

<template>
  <div class="server-time">
    <p>Time: {{ time.split(' ')[0] }}</p>
    <p>Date: {{ time.split(' ')[1] }}</p>
  </div>
</template>

<style scoped lang="scss">
.server-time {
  padding: 10px;
  bottom: 10px;
  position: absolute;
}
</style>