export const camelize = (str) => {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase()
        })
        .replace(/\s+/g, '')
}


export const formatTime = (seconds) => {
    // Ensure the input is a number
    if (isNaN(seconds) || seconds < 0) {
        return "Invalid input";
    }

    // Calculate hours, minutes, and seconds
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let secs = seconds % 60;

    // Pad the values with leading zeros if necessary
    hours = String(hours);
    minutes = String(minutes);
    secs = String(secs);

    // Return the formatted time
    return `${hours}:${minutes}:${secs}`;
}

export const timeFormat = (value, mode = 'all') => {
    if (isNaN(value) || value < 0) {
        return "Invalid input";
    }
    let h, m, s
    s = value
    if (s > 0) {
        m = Math.floor(s / 60)
        s = s - m * 60
    } else m = 0
    if (m > 0) {
        h = Math.floor(m / 60)
        m = m - h * 60
    } else h = 0
    const dt = {
        hr: (h > 0 ? h + ' h' : ''),
        mn: (m > 0 ? String(m).padStart(2, '0') + ' m' : ''),
        sc: (s > 0 ? String(s).padStart(2, '0') + ' s' : '')
    }
    if (mode === 'all') return dt.hr + ' ' + dt.mn + ' ' + dt.sc
    if (mode === 'hour') return dt.hr
    if (mode === 'minute') return dt.mn
    if (mode === 'second') return dt.sc
    if (mode === 'hour-minute') return (h > 0 ? h : '') + ':' + (m > 0 ? String(m).padStart(2, '0') : '')
}