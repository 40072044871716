<template>
  <el-drawer
      :title="`${$t('Delete')} ${ title }`"
      :size="$store.getters.isMobileWidth ? 230 : 350"
      :visible.sync="deleteModal"
      :direction="$store.getters.drawerDirections"
      :modal="true"
      :before-close="closed">
    <div class="drawer_content">
      <span>{{ $t('Are you want delete the') }} {{ title }}: <strong>{{ item['name'] || item['title'] }}</strong>?</span>
      <span class="footer_button">
          <el-button class="cancel_btn" @click="deleteModal = false">{{ $t('Cancel') }}</el-button>
          <el-button class="confirm_btn" type="danger" :loading="deleting" :disabled="deleting"
                     @click="remove">{{ $t('Delete') }}</el-button>
        </span>
    </div>
  </el-drawer>
</template>



<script>
export default {
  name: "Delete",
  props: {
    title: {
      type: String,
      default: ''
    },
    deleting: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      deleteModal: false,
      item: {
        name: 'Something'
      }
    }
  },
  methods: {
    call (item) {
      this.item = item
      this.deleteModal = true
    },
    closed () {
      this.deleteModal = false
      this.item = { name: 'Something' }
    },
    remove() {
      this.$emit('removed', this.item)
    }
  }
}
</script>

<style scoped lang="scss">
.drawer_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  font-size: 16px;

  .footer_button {
    bottom: 10px;
    padding: 20px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;

    .cancel_btn {
      width: 50%;
    }

    .confirm_btn {
      width: 50%;
    }
  }
}
</style>