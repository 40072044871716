// startEvent
import ai from "@/utils/ai";
import ls from '@/utils/ls'
export default {
    state: {
        event: null,
        starting: null,
        stopping: false
    },
    getters: {
        activeEvent: state => state['event'],
        startingEvent: state => state['starting'],
        stoppingEvent: state => state['stopping']
    },
    mutations: {
        SET_ACTIVE_EVENT (state, payload) {
            state.event = payload
        },
        SET_STARTING (state, payload) {
            state.starting = payload
        },
        SET_STOPPING (state, payload) {
            state.stopping = payload
        },
    },
    actions: {
        async startEvent ({ commit }, payload) {
            commit('SET_STARTING', true)
            try {
                const response = await ai.post('/events/start', payload)
                commit('SET_STARTING', false)
                return response
            } catch (e) {
                commit('SET_STARTING', false)
            }
        },
        async stopEvent ({ commit }, payload) {
            commit('SET_STOPPING', true)
            try {
                const response = await ai.post('/events/stop', payload)
                commit('SET_STOPPING', false)
                return response
            } catch (e) {
                commit('SET_STOPPING', false)
            }
        },
        async getActiveEvent ({ commit }) {
            try {
                const response = await ai.get('/events/active-event')
                commit('SET_ACTIVE_EVENT', response.data)
                return response.data
            } catch (e) {
                return 'error'
            }
        },
        async setTimeEvent ({ state, commit }, payload) {
            commit('SET_ACTIVE_EVENT', {
                ...state.event,
                start: payload
            })
        }
    }
}