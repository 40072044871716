<template>
  <div v-if="total > 0" class="pagination">
    <div class="info_pagination">{{ $t('From') }} {{ from }} {{ $t('to') }} {{ to }}. {{ $t('Total') }} {{ total }}</div>
    <el-pagination
        background
        :page-size="limit"
        :current-page="page"
        @current-change="change"
        layout="prev, pager, next"
        :total="totalAmount">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    total: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  data () {
    return {
      // limit: 10
    }
  },
  methods: {
    change (e) {
      this.$emit('change', {
        page: e,
        limit: this.limit
      })
    }
  },
  computed: {
    from () {
      return ((this.page - 1) * this.limit) + 1
    },
    to () {
      const _p = this.page
      const _s = this.limit
      return (_p * _s) > this.total ? this.total : _p * _s
    },
    totalAmount (){
      return this.total
    }
  }
}
</script>

<style>
.pagination {
  display: flex;
  margin: 5px 10px;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
}
</style>