import Vue from 'vue'
import Vuex from 'vuex'
import crud from "@/store/crud";
import alert from "@/store/alert";
import auth from "@/store/auth";
import other from "@/store/other";
import reports from "@/store/reports";
import additionalEvents from "@/store/events";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    alert,
    auth,
    additionalEvents,
    other,
    reports,
    eventTemps: crud('event-temps'),
    eventCategories: crud('event-categories'),
    events: crud('events'),
    monetizationTypes: crud('monetizations/types'),
  }
})
