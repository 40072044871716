import ls from "@/utils/ls";

const whiteList = ['login', 'register']
export default (to, from, next) => {
    if (ls.g('token')) {
        if (whiteList.includes(to.name) || !to.name) {
            next('/')
        } else next()
    } else {
        if (whiteList.includes(to.name)) {
            next()
        } else next({ name: 'login' })
    }
}