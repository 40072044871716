import i18n from '@/locales'
export default {
    state: {},
    getters: {},
    mutations: {
    },
    actions: {
        alert({}, payload) {
            const { type, content, title } = payload
            window.notify({
                title: i18n.t(title),
                message: content,
                type: type
            })
        }
    }
}