<template>
  <div class="sidebar">
    <a class="logo-expand">GTime</a>
    <div class="side-wrapper">
      <div class="side-title">MENU</div>
      <div class="side-menu">
        <a v-for="route in routes" :key="route.name"
           :class="{ 'sidebar-link': true, 'is-active': $route.name === route.name }"
           @click="$router.push({ name: route.name })">
          <i :class="route.meta.icon"></i>
          {{ route.meta.title }}
        </a>
        <a class="sidebar-link" @click="visible = true">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <path
                d="M14 7.63636L14 4.5C14 4.22386 13.7761 4 13.5 4L4.5 4C4.22386 4 4 4.22386 4 4.5L4 19.5C4 19.7761 4.22386 20 4.5 20L13.5 20C13.7761 20 14 19.7761 14 19.5L14 16.3636"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 12L21 12M21 12L18.0004 8.5M21 12L18 15.5" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Log Out
        </a>
      </div>
      <ServerTime/>
    </div>
    <el-dialog
        title="Log out confirmation"
        :visible.sync="visible"
        :width="$store.getters.modalWidths"
        top="15%"
        center>
      <span>Do you want log out from application?</span>
      <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">Cancel</el-button>
            <el-button type="primary" @click="logOut">Confirm</el-button>
          </span>
    </el-dialog>
  </div>
</template>

<script>
import routes from "@/constants/routes";
import Vue from "vue";
import ServerTime from "@/components/ServerTime.vue";

export default {
  name: "Sidebar",
  components: {ServerTime},
  data() {
    return {
      visible: false
    };
  },
  methods: {
    handleClose(e) {
      this.visible = false;
      console.log(';', e)
    },
    logOut() {
      this.$store.dispatch('logOut').then(() => {
        this.$router.push({name: 'login'})
        Vue.prototype.$user = null
      })
    }
  },
  computed: {
    routes() {
      return routes.filter((route) => route.meta && route.meta.auth);
    }
  },
}
</script>

<style scoped lang="scss">

.sidebar {
  width: 220px;
  height: 100vh;
  padding: 30px;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  flex-direction: column;
  background: var(--theme-bg);
  flex-shrink: 0;
  transition-duration: 0.2s;
  overflow-x: hidden;

  &-link {
    &:hover,
    &.is-active {
      color: var(--bg-default);
      font-weight: 600;

      i {
        color: var(--theme-bg);
        background: var(--bg-default);
      }
    }
  }

  &.collapse {
    width: 90px;
    border-right: 1px solid var(--border-color);

    .logo-expand,
    .side-title {
      display: none;
    }

    .logo {
      display: flex;
    }

    .side-wrapper {
      width: 30px;
    }

    .side-menu svg {
      margin-right: 30px;
    }
  }
}

.side-menu {
  display: flex;
  flex-direction: column;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;

    & + a {
      margin-top: 26px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  i {
    width: 30px;
    padding: 8px;
    border-radius: 10px;
    background-color: #3C5B6F;
    flex-shrink: 0;
    margin-right: 16px;
  }

  svg {
    width: 30px;
    padding: 8px;
    border-radius: 10px;
    background-color: #3C5B6F;
    flex-shrink: 0;
    margin-right: 16px;

    &:hover {
      color: #fff;
    }
  }
}

.side-title {
  //color: white;
  font-size: 12px;
  letter-spacing: 0.07em;
  margin-bottom: 24px;
}

.side-wrapper {
  //border-bottom: 1px solid var(--border-color);
  padding: 36px 0;
  width: 145px;

  & + & {
    border-bottom: none;
  }
}

@media screen and (max-width: 450px) {
  .sidebar {
    display: none;
  }
}
</style>
