import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en'
import ru from './ru'
import uz from './uz'

Vue.use(VueI18n)
const messages = {
    uz,
    en,
    ru
}

export default new VueI18n({
    locale: 'uz',
    fallbackLocale: ['uz', 'en', 'ru'],
    messages,
})