import ai from "@/utils/ai";
import ls from '@/utils/ls'
import Vue from 'vue'
import i18n from '@/locales'
export default {
    state: {
        user: null,
        token: null,
        loading: false
    },
    getters: {
        user: state => state['user'],
        token: state => state['token'],
        loadAuth: state => state['loading']
    },
    mutations: {
        SET_TOKEN (state, payload) {
            state.token = payload
        },
        SET_LOADER (state, payload) {
            state.loading = payload
        },
        SET_USER (state, payload) {
            state.user = payload
        },
    },
    actions: {
        async login ({ commit, dispatch }, payload) {
            commit('SET_LOADER', true)
            try {
                const response = await ai.post('/auth/login', payload)
                await ls.s('token', response['token'])
                commit('SET_TOKEN', response['token'])
                commit('SET_LOADER', false)
                await dispatch('getMe').then(res => {
                    dispatch('alert', {
                        type: 'success',
                        title: 'Success',
                        content: i18n.t('You have successfully logged in')
                    })
                })
                return response
            } catch (e) {
                commit('SET_LOADER', false)
                throw new Error(e.message)
            }
        },
        async register ({ commit, dispatch }, payload) {
            commit('SET_LOADER', true)
            try {
                const response = await ai.post('/auth/register', payload)
                commit('SET_LOADER', false)
                dispatch('alert', {
                    type: 'success',
                    title: 'Success',
                    content: i18n.t('You have successfully registered')
                })
                return response
            } catch (e) {
                commit('SET_LOADER', false)
                return 'error'
            }
        },
        async logOut ({ commit }) {
            await ls.r('token')
            commit('SET_TOKEN', null)
            commit('SET_USER', null)
            return 'logged out'

        },
        async getMe ({ commit }) {
            try {
                const _me = await ai.get('/auth/me', { populate: '*' })
                Vue.prototype.$user = _me
                commit('SET_USER', _me)
                return _me
            } catch (e) {
                return 'error'
            }
        },
        async getServerTime () {
            try {
                return await ai.get('/events/time')
            } catch (e) {
                return 'error'
            }
        }
    }
}