export default {
    state: {
        windowWidth: 0
    },
    getters: {
        windowWidth: state => state['windowWidth'],
        modalWidths: (state) => {
            const x = state.windowWidth
            if (x >= 1261) {
                return '35%'
            } else if (x >= 1025 && x <= 1260) {
                return '40%'
            } else if (x >= 769 && x <= 1024) {
                return '60%'
            } else if (x >= 481 && x <= 768) {
                return '80%'
            } else if (x <= 480) {
                return '90%'
            } else {
                return '30%'
            }
        },
        drawerDirections: state => {
            const x = state.windowWidth
            if (x <= 480) {
                return 'btt'
            } else {
                return 'rtl'
            }
        },
        isMobileWidth: state => {
            return state.windowWidth <= 480
        }
    },
    mutations: {
        SET_WINDOW_WIDTH (state, payload) {
            state.windowWidth = payload
        }
    },
    actions: {
        setWidth({ commit }, payload) {
            commit('SET_WINDOW_WIDTH', payload)
        }
    }
}