<script>
import Footer from "@/components/Footer.vue";
export default {
  name: "auth",
  components: {Footer}
}
</script>

<template>
    <router-view/>
</template>

<style scoped lang="scss">

</style>