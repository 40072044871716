<script>
import ActiveEventBox from "@/components/ActiveEventBox.vue";

export default {
  name: "Header",
  components: {ActiveEventBox},
  data() {
    return {}
  },
  computed: {
    event() {
      return this.$store.getters.activeEvent
    }
  },
  mounted() {
    this.$store.dispatch('getActiveEvent')
  }
}
</script>

<template>
  <div class="top-header">
    <div class="header">
      <div class="search-bar">
        <input type="text" placeholder="Search">
      </div>
      <div class="user-settings">
        <img class="user-img" src="https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png">
        <div class="user-name">{{ $user.name }}</div>
      </div>
    </div>
    <transition name="fade">
      <ActiveEventBox ref="activeEventBox" v-if="event && event.eventTemp"/>
    </transition>
  </div>
</template>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: all .3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  transform: translateY(-30px);
  opacity: 0;
}

.header {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  z-index: 9;
  position: relative;
  top: 0;
}

.search-bar {
  height: 40px;
  display: flex;
  width: 100%;
  max-width: 300px;

  input {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    padding: 0 40px 0 16px;
    box-shadow: 0 0 0 1.5px rgba(223, 208, 184, 0.44);
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%23717790c7'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 96%;
    outline: none;
    color: var(--font-color);
  }
}

.user {
  &-settings {
    display: flex;
    align-items: center;
    svg {
      width: 10px;
      flex-shrink: 0;
      @media screen and (max-width: 575px) {
        display: none;
      }
    }
  }

  &-img {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 50%;
  }

  &-name {
    color: #948979;
    font-size: 14px;
    margin: 0 6px 0 12px;
    @media screen and (max-width: 575px) {
      display: none;
    }
  }
}

@media screen and (max-width: 450px) {
  .search-bar {
    height: 35px;
    max-width: 250px;
  }
  .header {
    padding: 15px 20px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}
</style>