<script>
import EventModal from "@/components/EventModal.vue";
import dayjs from "dayjs";
export default {
  name: "ActiveEventBox",
  components: {EventModal},
  data() {
    return {
      timer: null
    }
  },
  methods: {
    stopEvent() {
      this.$refs.eventModal.call(this.event.eventTemp, 'stop')
    },
    startTimer () {
      this.timer = setInterval(() => {
        if (!this.event) return
        this.$store.commit('SET_ACTIVE_EVENT', { ...this.event })
      }, 1000)
    }
  },
  mounted() {
    this.startTimer()
  },
  computed: {
    event() {
      return this.$store.getters['activeEvent']
    },
    duration() {
      if (!this.event || !this.event.start) return 0
      return this.$tf(dayjs().diff(this.event.start, 'second'))
    }
  }
}
</script>

<template>
  <div>
    <EventModal ref="eventModal"/>
    <div class="active-event" @click="stopEvent">
        <div class="title">
          <i class="el-icon-video-pause play-button"></i>
          {{ event.eventTemp.title }}
          | {{ duration }}
        </div>
      </div>
  </div>
</template>

<style scoped lang="scss">

.active-event {
  display: flex;
  align-items: center;
  padding: 5px 30px;
  background-color: var(--bg-default);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  .title {
    display: flex;
    align-items: center;

    .play-button {
      color: var(--theme-bg);
      font-size: 28px;
      margin-right: 10px;
    }
  }

  transition: all 0.3s;

  &:hover {
    .play-button {
      color: var(--bg-default);
    }

    cursor: pointer;
    background-color: var(--theme-bg);
    color: var(--bg-default);
  }
}

</style>