<script>
export default {
  name: "Footer"
}
</script>

<template>
  <footer>
    <p>
      Created with <i class="fa fa-heart"></i> by
      <a target="_blank" href="https://kebyo.me">Oybek Mukhiddinov</a>
      - If you are wont to support me you can do it
      <a target="_blank" href="https://buymeacoffee.com/oybek1024">☕️ By me a coffee</a>.
    </p>
  </footer>
</template>

<style scoped lang="scss">
@media screen and (max-width: 450px) {
  footer {
    font-size: 12px !important;
  }
}
footer {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
}

footer p {
  margin: 8px 0;
}

footer i {
  color: red;
}

footer a {
  color: #3c97bf;
  text-decoration: none;
}
</style>