<template>
  <div>
    <mainLayout v-if="isAuth && $store.getters.user"/>
    <authLayout v-if="!isAuth"/>
    <Footer v-if="!isAuth"/>
  </div>
</template>

<script>
import mainLayout from "@/layout/main-layout.vue";
import authLayout from "@/layout/auth-layout.vue";
import ls from '@/utils/ls'
import Loader from "@/components/Loader.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Footer,
    Loader,
    mainLayout,
    authLayout
  },
  created() {
    const width = window.innerWidth
    if (ls.g('token')) {
      this.$store.dispatch('getMe').catch(err => {
        this.$store.dispatch('logOut')
      })
    }
    this.$store.dispatch('setWidth', width)

    window.onresize = function(event) {
      this.$store.dispatch('setWidth', event.target.innerWidth)
    }.bind(this);
  },
  computed: {
    isAuth() {
      // console.log(this.$route.meta)
      return this.$route.meta['auth'];
    }
  }
}
</script>

<style lang="scss">
</style>
