<template>
  <div class="container">
    <SlideBar/>
    <div class="wrapper">
      <Header/>
      <div class="main-container">
        <router-view/>
      </div>
      <NavigationBar/>
    </div>
  </div>
</template>

<script>
import SlideBar from "@/components/Sidebar.vue";
import AppBar from "@/components/AppBar.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import NavigationBar from "@/components/NavigationBar.vue";

export default {
  name: "main-layout",
  components: {
    NavigationBar,
    Header,
    Footer,
    SlideBar,
    AppBar
  }
}
</script>

<style scoped lang="scss">


.container {
  color: var(--body-color);
  background-color: #ffffff;
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  font-size: 15px;
  font-weight: 500;
  position: relative;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.main-container {
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px;
  flex-grow: 1;
  overflow: auto;
}

@media screen and (max-width: 450px) {
  .container {
    border-radius: 0;
    height: calc(100vh - 100px);
    box-shadow: none;
  }
  .main-container {
    padding: 0 20px 20px;
  }
}
</style>