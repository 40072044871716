<template>
  <el-header>
    <div class="left-section">
      <i :class="{
      'el-icon-s-unfold': isCollapse,
      'el-icon-s-fold': !isCollapse
    }" @click="handleSlide"></i>
      <portal-target class="portal" name="page-title"></portal-target>
    </div>
    <div class="right-section">
      <portal-target class="right-portal" name="global-search"></portal-target>
      <el-dropdown trigger="click" @command="handleDropdown" style="margin-right: 10px; cursor: pointer">
        <span>
          <i class="el-icon-setting" style="margin-right: 5px"></i>
          {{ $t('Settings') }}
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="sync" v-if="role === 'admin'">{{ $t('sync') }}</el-dropdown-item>
          <el-dropdown-item command="logout">{{ $t('logout') }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog
        :title="$t('logout')"
        :visible.sync="logOutModal"
        width="30%"
        center>
      <span>{{ $t('Are you want leave from the app') }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="logOutModal = false">{{ $t('Cancel') }}</el-button>
        <el-button type="danger" @click="logOut">{{ $t('logout') }}</el-button>
      </span>
    </el-dialog>
  </el-header>
</template>

<script>
import Vue from "vue";

export default {
  name: "AppBar",
  data() {
    return {
      isCollapse: false,
      logOutModal: false
    };
  },
  computed: {
    role() {
      return this.$user.role.description;
    }
  },
  mounted() {
    // console.log(this.$user)
  },
  methods: {
    handleSlide() {
      this.isCollapse = !this.isCollapse;
      this.$emit("slide", this.isCollapse);
    },
    handleDropdown(e) {
      if (e === 'logout') {
        this.logOutModal = true
      }
      if (e === 'sync') {
        this.$router.push({name: 'sync'})
      }
    },
    logOut() {
      this.$store.dispatch('logOut').then(() => {
        this.$router.push({name: 'login'})
        Vue.prototype.$user = null
      })
    }
  }
}
</script>

<style lang="scss">
.right-section {
  display: flex;
  align-items: center;

  .right-portal {
    margin-right: 15px;
  }
}

.left-section {
  display: flex;
  align-items: center;

  i {
    font-size: 24px;
    color: #adacac;
  }

  .portal {
    margin-left: 15px;
    font-size: 18px;
  }
}

.el-header {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  color: #333;
  //height: 60px;
}
</style>