<script>
import Vue from "vue";

export default {
  name: "NavigationBar",
  data() {
    return {
      visible: false,
      items: [
        {icon: "el-icon-s-data", text: "Dashboard", route: 'home'},
        {icon: "el-icon-date", text: "My Events", route: 'my-events'},
        {icon: "el-icon-tickets", text: "Events", route: 'events'},
        {icon: "el-icon-user", text: "Profile", route: 'profile'}
      ]
    };
  },
  methods: {
    handleClose(e) {
      this.visible = false;
    },
    logOut() {
      this.$store.dispatch('logOut').then(() => {
        this.$router.push({name: 'login'}).catch()
        Vue.prototype.$user = null
      })
    }
  }
}
</script>

<template>
  <div>
    <el-drawer
        title="Exit"
        size="25%"
        :visible.sync="visible"
        direction="btt"
        :modal="true"
        :before-close="handleClose">
      <div class="drawer_content">
        <p>Do you want log out from application?</p>
        <span class="footer_button">
          <el-button class="cancel_btn" @click="visible = false">Cancel</el-button>
          <el-button class="confirm_btn" type="primary" @click="logOut">Confirm</el-button>
        </span>
      </div>
    </el-drawer>
    <div class="navigation">
      <ul>
        <li v-for="item in items" :key="item.text" :class="{ 'active': $route.name === item.route }"
            @click="$router.push({ name: item.route })">
          <i :class="item.icon"/>
          {{ item.text }}
        </li>
        <li @click="visible = true">
          <i class="el-icon-arrow-right"/>
          Exit
        </li>
      </ul>
    </div>
  </div>

</template>

<style scoped lang="scss">
.drawer_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  font-size: 16px;
  .footer_button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
    .cancel_btn {
      width: 50%;
    }
    .confirm_btn {
      width: 50%;
    }
  }
}

.navigation {
  border: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;

  ul {
    background-color: var(--theme-bg);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    padding-top: 2px;
    margin: 0;
    height: 60px;

    li {
      color: white;
      width: 80px;
      font-size: 10px;
      font-weight: 500;
      cursor: pointer;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease;

      &:hover,
      &.active {
        color: var(--bg-default);
        font-weight: 600;

        i {
          color: var(--theme-bg);
          background: var(--bg-default);
        }
      }

      &:active {
        transform: scale(0.9);
      }

      i {
        font-size: 20px;
        padding: 5px;
        border-radius: 10px;
        margin-bottom: 4px;
        background-color: #3C5B6F;
        color: white;
        flex-shrink: 0;
      }
    }
  }
}

@media screen and (min-width: 451px) {
  .navigation {
    display: none;
  }
}

/* Indicator Transitions */
.navigation ul li:nth-child(1).active ~ .indicator {
  transform: translateX(calc(70px * 0));
}

.navigation ul li:nth-child(2).active ~ .indicator {
  transform: translateX(calc(70px * 1));
}

.navigation ul li:nth-child(3).active ~ .indicator {
  transform: translateX(calc(70px * 2));
}

.navigation ul li:nth-child(4).active ~ .indicator {
  transform: translateX(calc(70px * 3));
}

.navigation ul li:nth-child(5).active ~ .indicator {
  transform: translateX(calc(70px * 4));
}

</style>