<template>
  <el-empty>
    <template #description>
      <h3>{{ title }}</h3>
    </template>
    <slot name="default"></slot>
  </el-empty>
</template>

<script>
export default {
  name: "EmptyBox",
  props: {
    title: {
      type: String,
      default: "No Data"
    }
  }
}
</script>

<style lang="scss">
.el-empty__image {
  svg {
    &:hover {
      path {
        stroke: none;
      }
    }
    path {
      stroke: none;
    }
  }
}
</style>