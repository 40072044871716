<template>
  <el-drawer
      :title="mode === 'start' ? 'Start event' : 'Stop event'"
      :size="$store.getters.isMobileWidth ? 300 : 350"
      :visible.sync="visible"
      :direction="$store.getters.drawerDirections"
      :modal="true"
      :before-close="close">
    <div class="drawer_content">
      <p>Are you want {{ mode === 'start' ? 'start' : 'stop' }} this event ?</p>
      <h3 v-if="selectedEvent">
        <span v-if="activeEvent && mode === 'start'"> {{ activeEvent?.eventTemp?.title  }} -> </span> {{ selectedEvent.title }}
      </h3>

      <el-form :model="form" ref="lateForm" label-position="right" class="demo-ruleForm" v-if="mode === 'start' && activeEvent">
        <el-form-item
            prop="lateMinute"
            :rules="[ { validator: validator, trigger: 'change' } ]"
        >
          <el-input
              style="width: 80%"
              type="number"
              size="large"
              placeholder="Late minute"
              prefix-icon="el-icon-alarm-clock"
              v-model="form.lateMinute">
            <template slot="append">Late minute</template>
          </el-input>
        </el-form-item>
      </el-form>

      <span class="footer_button">
          <el-button  class="cancel_btn" @click="close">{{ $t('Cancel') }}</el-button>
          <el-button size="medium" class="confirm_btn" type="primary" :disabled="loading"
                     @click="submit">
            <div>
              <el-icon style="font-size: 25px" :class="loading ? 'el-icon-loading' : buttonNameAndIcon().icon"/>
            <span>{{ buttonNameAndIcon().name }}</span>
            </div>
          </el-button>
        </span>
    </div>
  </el-drawer>
<!--  <el-dialog-->
<!--      :title="mode === 'start' ? 'Start event' : 'Stop event'"-->
<!--      @closed="close"-->
<!--      :visible.sync="visible"-->
<!--      top="20vh"-->
<!--      :width="$store.getters.modalWidths"-->
<!--      center>-->
<!--    <div class="event-starter">-->
<!--      <p>Are you want {{ mode === 'start' ? 'start' : 'stop' }} this event ?</p>-->
<!--      <h2 v-if="selectedEvent"><span v-if="activeEvent && mode === 'start'"> {{-->
<!--          activeEvent?.eventTemp?.title-->
<!--        }} -> </span> {{ selectedEvent.title }}</h2>-->
<!--      <div style="margin-bottom: 25px" v-if="mode === 'start' && activeEvent">-->
<!--        <el-form :model="form" ref="lateForm" label-position="right" class="demo-ruleForm">-->
<!--          <el-form-item-->
<!--              prop="lateMinute"-->
<!--              :rules="[ { validator: validator, trigger: 'change' } ]"-->
<!--          >-->
<!--            <el-input-->
<!--                style="width: 65%"-->
<!--                type="number"-->
<!--                size="large"-->
<!--                placeholder="Late minute"-->
<!--                prefix-icon="el-icon-alarm-clock"-->
<!--                v-model="form.lateMinute">-->
<!--              <template slot="append">minute</template>-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--        </el-form>-->
<!--      </div>-->
<!--      <i v-if="!loading" :class="{-->
<!--        'el-icon-video-play': mode === 'start' && !activeEvent,-->
<!--        'el-icon-video-pause': mode === 'stop' && activeEvent,-->
<!--        'el-icon-refresh': mode === 'start' && activeEvent,-->
<!--      }" @click="submit"/>-->
<!--      <i v-else class="el-icon-loading"/>-->
<!--    </div>-->
<!--  </el-dialog>-->
</template>

<script>
import {mapGetters} from "vuex";
import dayjs from "dayjs";
import bridge from "@/utils/bridge";

export default {
  name: "EventModal",
  data() {
    return {
      selectedEvent: {title: ''},
      visible: false,
      mode: 'start',
      form: {
        lateMinute: 0
      }
    }
  },
  computed: {
    ...mapGetters(['startingEvent', 'stoppingEvent', 'activeEvent']),
    loading() {
      return this.startingEvent || this.stoppingEvent
    }
  },
  methods: {
    buttonNameAndIcon() {
      if (this.mode === 'start' && !this.activeEvent) {
        return {
          name: 'Start',
          icon: 'el-icon-video-play'
        }
      }
      if (this.mode === 'start' && this.activeEvent) {
        return {
          name: 'Change',
          icon: 'el-icon-refresh'
        }
      }
      if (this.mode === 'stop' && this.activeEvent) {
        return {
          name: 'Stop',
          icon: 'el-icon-video-pause'
        }
      }
      return {
        name: 'Start',
        icon: 'el-icon-video-play'
      }
    },
    validator(rule, value, callback) {
      const { start } = this.activeEvent
      const diff = dayjs(start).diff(dayjs().subtract(value, 'minute'), 'minute')
      if (!/^\d+$/.test(value) || !(value > -1) || !(diff < 0)) {
        callback(new Error(`invalid value`));
      } else {
        callback();
      }
    },
    submit() {
      const now = dayjs().subtract(5, 'hour').format('YYYY-MM-DD HH:mm:ss')
      if (this.mode === 'start' && !this.activeEvent) {
        this.$store.dispatch('startEvent', {
          eventTemp: this.selectedEvent.id,
          start: now
        }).then(() => {
          this.$store.dispatch('getActiveEvent')
          this.close()
          bridge.$emit('startEvent')
        })
      }
      if (this.mode === 'start' && this.activeEvent) {
        this.$refs.lateForm.validate(valid => {
          if (valid) {
            const lateTime = dayjs(now).subtract(+this.form.lateMinute, 'minute').format('YYYY-MM-DD HH:mm:ss')
            this.$store.dispatch('stopEvent', {
              end: lateTime
            }).then(res => {
              this.$store.dispatch('startEvent', {
                eventTemp: this.selectedEvent.id,
                start: lateTime
              }).then(() => {
                this.$store.dispatch('getActiveEvent')
                this.close()
                bridge.$emit('refreshEvent')
              })
            })
          }
        })
      }
      if (this.mode === 'stop' && this.activeEvent) {
        this.$store.dispatch('stopEvent', {
          end: now
        }).then(res => {
          this.close()
          bridge.$emit('stopEvent')
          this.$store.commit('SET_ACTIVE_EVENT', null)
        })
      }
    },
    call(event, mode = 'start') {
      this.mode = mode
      this.selectedEvent = event
      this.visible = true
    },
    close() {
      if (this.$refs.lateForm) {
        this.$refs.lateForm.resetFields()
      }
      this.visible = false
    }
  }
}
</script>

<style scoped lang="scss">
.demo-ruleForm {
  margin-top: 20px;
  text-align: center;
  width: 100%;
}
.drawer_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  font-size: 16px;

  .footer_button {
    bottom: 10px;
    padding: 20px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;

    .cancel_btn {
      width: 50%;
    }

    .confirm_btn {
      width: 50%;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.demo-input-label {
  display: inline-block;
  width: 100px;
}

.event-starter {
  text-align: center;

  p {
    font-size: 18px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  i {
    font-size: 100px;
    color: var(--theme-bg);
    margin-bottom: 20px;
    border-radius: 50%;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      color: var(--bg-default);
      background-color: var(--theme-bg);
    }

    &:active {
      transform: scale(0.9);
    }
  }

}
</style>